import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/components/Home.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/association',
    name: 'association',
    component: () => import('@/views/association/association.vue')
  }
]

const router = new VueRouter({
  routes
})

// 防止连续点击多次路由报错
let routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(err => err)
}


export default router
