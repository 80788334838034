<template>
    <div>
        <div class="customer">
            <ul>
                <li>
                    <img src="@/assets/kefu.png" alt="">
                    <h2>在线资询</h2>
                </li>
                <li>
                    <img src="@/assets/dianhua.png" alt="">
                    <h2>在线资询</h2>
                </li>
            </ul>
        </div>
    </div>
</template>



<script>
export default {
    name: 'home',
    data() {
        return {
        }
    },

}
</script>
<style scoped>
.customer {
    position: fixed;
    width: 75px;
    right: 50px;
    bottom: 10%;
    z-index: 999;
}

.customer ul li {
    margin-bottom: 8px;
    height: 75px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #3366CC;
    text-align: center;
    cursor: pointer;
}

.customer ul li img {
    width: 30px;
    height: 30px;
    display: block;
    margin: 0 auto;
    margin-bottom: 8px;

}

.customer ul li h2 {
    font-size: 14px;
    font-weight: 500;
    color: #FFFFFF;

}
</style>