<template>
    <div class="footer-box">
        <div class="footer-top">
            <div class="footer-top-w centerBox">
                <div class="footer-top-left">
                    <div class="tit-h2">
                        联系我们
                    </div>
                    <p>电话：0411-35689999</p>
                    <p>邮件：support@belllab.com.cn</p>
                    <p>时间：9:00-18:00</p>
                    <p>建议与反馈</p>
                </div>
                <div class="footer-top-right">
                    <div class="footer-nav">
                        <ul>
                            <li v-for="(item, index) in footerNav" :key="index">{{ item.tit }}</li>
                        </ul>
                    </div>
                    <div class="footer-top-bottom">
                        <div class="footer-top-bottom-left">
                            <div class="tit-h3">友情链接</div>
                            <div class="tags">
                                <span v-for="(item, index) in tags" :key="index">
                                    <a>{{ item.tit }}</a>
                                    <span v-if="index != tags.length - 1">|</span>
                                </span>
                            </div>
                        </div>
                        <div class="ma">
                            <img :src="ma" alt="">
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="banquan-box flex-center">
            <div class="centerBox banquan-box-w">
                <p>© 2011-2023 大连捷成轴承发展有限公司 | 京ICP备123456号 | 京ICP证123456号 | 京公网安备11012344444号</p>
                <p>CopyRight @ 长海海产品交易平台 2023 </p>
            </div>

        </div>

    </div>
</template>
    
<script>
export default {
    name: 'myFooter',
    data() {
        return {
            ma: require('@/assets/ma.png'),
            footerNav: [
                {
                    id: '1',
                    tit: '轴承协会',
                    tits: '|',
                    urlTo: '/aboutUs'
                },
                {
                    id: '2',
                    tit: '轴承商城',
                    tits: '|',
                    urlTo: '/helpCenter'
                },
                {
                    id: '3',
                    tit: '产业服务',
                    tits: '|',
                    urlTo: '/afterSalesService'
                },
                {
                    id: '4',
                    tit: '成功案例',
                    tits: '|',
                    urlTo: '/BusinessCooperation'
                }
            ],
            tags: [
                {
                    id: '1',
                    tit: "中国机床工具工业协会",
                },

                {
                    id: '2',
                    tit: "中国农业机械工业协会",
                },
                {
                    id: '3',
                    tit: "中国机械通用零部件工业协会",
                },
                {
                    id: '4',
                    tit: "中国机械工业联合会",
                },
                {
                    id: '5',
                    tit: "机经网",
                },
                {
                    id: '6',
                    tit: "中国机械采购网",
                },
                {
                    id: '7',
                    tit: "汽车制造网",
                },
                {
                    id: '8',
                    tit: "光采网赛和轴承网",
                },
                {
                    id: '9',
                    tit: "中国特钢企业协会",
                },
                {
                    id: '10',
                    tit: "中国重型机械工业协会",
                },
                {
                    id: '11',
                    tit: "中国通用机械工业协会",
                },
            ],
        }
    },
    created() {
    },
    methods: {
    },
}
</script>
    
<style scoped>
.ma img {
    width: 120px;
    height: 120px;
}

.footer-top-bottom-left {
    flex: 1;
    margin-right: 54px;
}

.tit-h3 {
    font-size: 16px;
    font-weight: 400;
    color: rgba(191, 200, 237, 0.94);
    margin-bottom: 28px;
}

.tags span a {
    font-size: 12px;
    font-weight: 400;
    color: #5867A4;
    margin-right: 10px;
    cursor: pointer;
}

.tags span span {
    font-size: 12px;
    font-weight: 400;
    color: #5867A4;
    margin-right: 10px;
}

.footer-top-bottom {
    display: flex;
    justify-content: space-between;
    padding-top: 22px;
}

.footer-nav {
    padding-bottom: 24px;
    border-bottom: 1px solid #5867A4;
    margin-bottom: 25px;
}

.footer-nav ul {
    display: flex;
}

.footer-nav ul li {
    font-size: 20px;
    font-weight: 400;
    color: rgba(191, 200, 237, 0.94);
    margin-right: 80px;
}

.footer-nav ul li:last-of-type {
    margin-right: 0;
}

.footer-top-right {
    flex: 1;
}

.footer-top-left p {
    font-size: 14px;
    font-weight: 400;
    color: #5867A4;
    line-height: 30px;
}

.footer-top-left {
    margin-right: 110px;
}

.tit-h2 {
    font-size: 28px;
    font-weight: 400;
    color: rgba(191, 200, 237, 0.94);
    margin-bottom: 34px;
}

.tit-h2::after {
    content: "";
    width: 58px;
    height: 4px;
    background: #2965F7;
    display: block;
    margin-top: 25px;
}

.footer-list>ul>li {
    cursor: pointer;
}

.footer-box {}

.footer-top {
    background: rgba(18, 31, 82, 0.94);
    padding: 50px 0;
}

.footer-top-w {
    display: flex;
    justify-content: space-between;
}

.banquan-box {
    border-top: 1px solid #656565;
    background: rgba(114, 122, 255, 1);
    height: 80px;
}

.banquan-box  p:first-of-type {
    font-size: 14px;
    font-weight: 400;
    color: #8192D5;
    margin-bottom: 6px;
}


.banquan-box  p:last-of-type {
    font-size: 14px;
    font-weight: 400;
    color: #C1C9EB;
}

.banquan-box p{
    text-align: center;
}

</style>
    