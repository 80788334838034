<template>
  <div class="header-box">
    <div class="header-top centerBox">
      <div class="header-top-box flex-row-between">
        <div class="logo">
          <img :src="logo" alt="">
        </div>
        <div class="header-top-right">
          <div class="flex-row-center">
            <span>会员中心</span>
            <span>|</span>
            <span>登录</span>
            <el-button type="primary">注册</el-button>
          </div>
        </div>
      </div>
    </div>

    <div class="nav">
      <div class="nav-w centerBox">
        <ul>
          <li v-for="(item, index) in navList" :key="index" :class="activeIndex == index ? 'on' : ''"
            @click="onGoto(index, item)">
            <span>{{ item.tit }}</span>
            <div class="erji" v-if="item.erji">
              <dl class="flex-row">
                <dt v-for="(ite, ind) in item.erji" :key="ind" class="flex-row-between">
                  <img :src="ite.img" alt="">
                  <div class="sanji flex-row-between">
                    <a v-for="(i, inx) in ite.list" :key="inx"> {{ i.tit }}</a>
                  </div>
                </dt>
              </dl>
            </div>
          </li>

        </ul>
      </div>
    </div>

  </div>
</template>
                                         
<script>
import router from '@/router';

// import { userBaseInfo } from '@/api/userApi/userApi'
export default {
  name: 'myHeader',
  data() {
    return {
      activeIndex: 0,
      logo: require('@/assets/logo.png'),
      navList: [
        {
          id: '1',
          tit: '首页',
          type: 'home',
          urlTo: '/'
        },

        {
          id: '2',
          tit: '轴承商城',
          type: 'shop',
        },
        {
          id: '3',
          tit: '钢材集采',
          type: 'shop',
        },
        {
          id: '4',
          tit: '智慧工厂',
          type: 'shop',
        },
        {
          id: '11',
          tit: '供应链平台',
          type: 'shop',
        },
        {
          id: '5',
          tit: '轴承协会',
          type: 'shop',
          urlTo: 'http://test.zhoucheng.cn/indexzc.html',
          erji: [
            {
              img: require('@/assets/13.png'),
              list: [
                {
                  id: '2-1',
                  tit: "协会动态",
                },
                {
                  id: '2-2',
                  tit: "行业资讯",
                },
                {
                  id: '2-3',
                  tit: "产业政策",
                },
                {
                  id: '2-4',
                  tit: "论坛交流",
                },
              ],
            },
            {
              img: require('@/assets/13.png'),
              list: [
                {
                  id: '2-5',
                  tit: "通知",
                },
                {
                  id: '2-6',
                  tit: "公告",
                },
                {
                  id: '2-7',
                  tit: "统计信息",
                },
                {
                  id: '2-8',
                  tit: "行业分析",
                },
              ],
            },
            {
              img: require('@/assets/13.png'),
              list: [
                {
                  id: '2-9',
                  tit: "关于协会",
                },
                {
                  id: '2-10',
                  tit: "协会活动",
                },
                {
                  id: '2-11',
                  tit: "会员单位",
                }
              ],
            },
            {
              img: require('@/assets/13.png'),
              list: [
                {
                  id: '2-12',
                  tit: "电子期刊",
                },
                {
                  id: '2-13',
                  tit: "出版物",
                }
              ],
            },
          ],
        },
        {
          id: '6',
          tit: '产业服务',
          type: 'shop',
          urlTo: 'http://test.zhoucheng.cn/cyfw.html'
        },
        {
          id: '7',
          tit: '成功案例',
          type: 'shop',
          urlTo: 'http://test.zhoucheng.cn/cgal.html'
        },
        {
          id: '8',
          tit: '生态招募',
          type: 'shop',
          urlTo: 'http://test.zhoucheng.cn/stzm.html'
        },
        {
          id: '9',
          tit: '商务合作',
          type: 'shop',
          urlTo: 'http://test.zhoucheng.cn/swhz.html'
        },
        {
          id: '10',
          tit: '关于我们',
          type: 'shop',
          urlTo: 'http://test.zhoucheng.cn/gywm.html'
        },

      ],
    }
  },
  watch: {
    // $route: {
    //   handler: function(route) {
    //     // console.log(route);
    //     if(route.name =='FirstPage'){
    //       this.activeIndex = '/'
    //     }
    //     else{

    //       this.activeIndex = route.path
    //     }
    //   },
    // }
  },
  created() {
    // this.getuserBaseInfo()

  },
  mounted() {
    if (localStorage.getItem("index")) {
      this.activeIndex = Number(localStorage.getItem("index"));
    } else {
      this.activeIndex = 0;
    }
    // window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    // window.removeEventListener("scroll", this.handleScroll);
  },



  computed: {
    //从vuex中获取token
    // getToken() {
    //   return sessionStorage.getItem("token");
    // }
  },

  methods: {
    onGoto(index, item) {
      // B2C
      if (item.id == 2) {
        window.open("http://mall.zhoucheng.cn", "_blank")
      } else if (item.id == 3) {
        window.open("http://web.zhoucheng.cn", "_blank")
      } else if (item.id == 4) {
        window.open("http://zhjx.bellmom.com/", "_blank")
      }else if(item.id == 11) {
        window.open("http://finance.zhoucheng.cn", "_blank")
      } else if(item.id !=1) {
        window.open(item.urlTo, "_blank")
      } else {
        this.$router.push({ query: { index: index }, path: item.urlTo })
        localStorage.setItem("index", index)
        if (this.$route.path == item.urlTo) {
          this.activeIndex = index
        }
      }
      // } else {
      //   this.$router.push({ query: { index: index }, path: item.urlTo })
      //   localStorage.setItem("index", index)
      //   if (this.$route.path == item.urlTo) {
      //     this.activeIndex = index
      //   }
      // }
    }
  }
}
</script>

<style scoped>
.sanji {
  width: 140px;
  flex-wrap: wrap;
}

.sanji a {
  font-size: 14px;
  font-weight: 500;
  color: #666666;
  line-height: 30px;
}

.erji dl {
  justify-content: space-between;
}

.erji dl dt img {
  width: 84px;
  height: 68px;
  flex-shrink: 0;
  margin-right: 20px;
}

.erji {
  position: absolute;
  width: 100%;
  background: rgba(245, 245, 245, 0.95);
  padding: 25px;
  top: 52px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s;
  z-index: 99;
  left: 0;
}

.nav ul {
  display: flex;
  align-items: center;
  position: relative;
}


.nav ul li {
  width: 108px;
  text-align: center;
  cursor: pointer;
  line-height: 52px;
}

.nav ul li:hover .erji {
  opacity: 1;
  visibility: visible;
}

.nav ul li span {
  font-size: 16px;
  font-weight: 500;
  color: #FFFFFF;
}

.nav ul li:hover {
  background: #3366CC;
}

.nav ul li.on {
  background: #3366CC;
}

.nav {
  background: #333B4B;
}

.header-top-right span {
  font-size: 14px;
  font-weight: 500;
  color: #888888;
  margin: 0 10px;
}

.header-top-right button {
  width: 72px;
  line-height: 32px;
  background: #3366CC;
  height: 32px;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 500;
  color: #FFFFFF;
  padding: 0;
  margin-left: 20px;
}

.logo {
  width: 380px;
}

.logo img {
  max-width: 100%;
  height: 68px;
}

.header-top-box {
  height: 96px;
}
</style>
