<template>
  <div class="hello">
    <!-- banner -->
    <div class="banner">
      <el-carousel trigger="click" height="500px">
        <el-carousel-item v-for="item in 4" :key="item">
          <img src="@/assets/51.png" alt="">
        </el-carousel-item>
      </el-carousel>
    </div>

    <!-- 协会 -->
    <div class="xiehui">
      <div class="xiehui-w centerBox">
        <div class="tabs">
          <ul>
            <li :class="tabIndex == index ? 'on' : ''" v-for="(item, index) in tabs" :key="index"
              @click="onChange(index)">{{ item.name }}</li>
          </ul>
          <div class="buton">
            <img src="@/assets/hand.png" alt="">
            <span>协会主页</span>
          </div>
        </div>

        <div class="xiehui-box">
          <div class="xiehui-left">
            <div class="xiehui-item">
              <ul>
                <li v-for="(item, index) in  tabs[tabIndex].tabsList" :key="index">
                  <img :src="require('@/assets/' + tabs[tabIndex].img)" alt="">
                  <div class="xiehui-item-right">
                    <h2>{{ item.tit }}</h2>
                    <p class="ellipsis-2">{{ item.dec }}</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div class="xiehui-right">
            <div class="lunbo">
              <div class="swiper">
                <img :src="require('@/assets/' + bigImg)" alt="">
                <h2>{{ bigTit }}</h2>
              </div>
              <div class="swiper-item">
                <ul>
                  <li :class="indexSwiper == index ? 'on' : ''" v-for="(item, index) in tabs[tabIndex].swiper"
                    :key="index" @click="onClick(item, index)"><img :src="require('@/assets/' + item.img)" alt=""></li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="more">
          更多
        </div>
      </div>
    </div>
    <!-- 核心业务场景 -->
    <!-- <div class="hexin centerBox">
      <div class="tit-h2">核心业务场景</div>
      <img src="@/assets/66.png" alt="">
    </div> -->

    <!-- 产业服务  -->
    <div class="chanye">
      <div class="chanye-w centerBox">
        <div class="tit-h2 write">产业服务</div>
        <div class="tit-dec">分层满足多样客户和行业诉求 </div>
        <div class="chanye-box">
          <div class="nav">
            <ul>
              <li :class="navIndex == index ? 'on' : ''" v-for="(item, index) in navList" :key="index"
                @click="onNav(index)">
                {{ item.tit }}
              </li>
            </ul>
          </div>
          <div class="chanye-right">
            <div class="tit-box">
              <h2>{{ navList[navIndex].tit }}</h2>
              <h3>{{ navList[navIndex].engTit }}</h3>
            </div>
            <div class="dec">
              <p>{{ navList[navIndex].dec }}</p>
            </div>
            <div class="list">
              <ul>
                <li v-for="(item, index) in navList[navIndex].list" :key="index">
                  <img :src="require('@/assets/' + item.img)" alt="">
                  <div class="list-right">
                    <h4>{{ item.name }}</h4>
                    <h5>{{ item.dec }}</h5>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 行业解决方案 -->
    <div class="jiejue centerBox">
      <div class="tit-h2">行业解决方案</div>
      <div class="jiejue-ul">
        <ul>
          <li v-for="(item, index) in jiejueList" :key="index">
            <img :src="require('@/assets/' + item.img)" alt="">
            <div class="jiejue-box">
              <h2>{{ item.tit }}</h2>
              <p>{{ item.dec }}</p>
            </div>
          </li>
        </ul>
      </div>

      <!-- 广告 -->
      <div class="gg centerBox">
        <img src="@/assets/b1.png" alt="">
      </div>
      <!-- 友情链接 -->
      <div class="youqing centerBox">
        <div class="tit-h2">友情链接</div>
        <div class="youqing-ul">
          <ul>
            <li v-for="(item, index) in youqingList" :key="index">
              <img :src="require('@/assets/' + item)" alt="">
            </li>
          </ul>
        </div>
      </div>
    </div>
    <customer></customer>
  </div>
</template>

<script>
import customer from '@/components/customer/customer.vue'
export default {
  name: 'home',
  components: {
    customer
  },
  data() {
    return {
      bigImg: "",
      tabs: [
        {
          id: "1",
          name: "协会动态",
          img: '13.png',
          swiper: [
            {
              tit: '1中国轴承工业协会第八届五次理事会及第九届会员大会',
              img: '6.png',
            },
            {
              tit: '2中国轴承工业协会第八届五次理事会及第九届会员大会',
              img: '5.png',
            },
            {
              tit: '3中国轴承工业协会第八届五次理事会及第九届会员大会',
              img: '13.png',
            },
            {
              tit: '4中国轴承工业协会第八届五次理事会及第九届会员大会',
              img: '51.png',
            },
            {
              tit: '5中国轴承工业协会第八届五次理事会及第九届会员大会',
              img: '6.png',
            },
          ],
          tabsList: [
            {
              tit: "协会动态2023哈尔滨HRB轴承蓄势待发",
              dec: "传送带高速运转、切割机上下翻飞、磨床中火花与水花相映成辉勾勒出新春最美的图画。带着哈尔滨元素的中国轴承知名品牌...",
            },
            {
              tit: "无锡机械工业2023年经济运行分析... ",
              dec: "2023年，受突如其来的新冠肺炎疫情和中美贸易战影响，无锡机械工业2023年经济，呈现前低后高、逐月回升的走...",
            },
            {
              tit: "中央财政奖补资金支持“专精特新”...",
              dec: "日前，财政部、工业和信息化部联合印发《关于支持“专精特新”中小企业高质量发展的通知》，明确中央财政安排100亿元以上奖补资...",
            }
          ]
        },
        {
          id: "2",
          name: "行业资讯",
          img: '13.png',
          swiper: [
            {
              tit: '中国轴承工业协会第八届五次理事会及第九届会员大会',
              img: '6.png',
            },
            {
              tit: '中国轴承工业协会第八届五次理事会及第九届会员大会',
              img: '6.png',
            },
            {
              tit: '中国轴承工业协会第八届五次理事会及第九届会员大会',
              img: '6.png',
            },
            {
              tit: '中国轴承工业协会第八届五次理事会及第九届会员大会',
              img: '6.png',
            },
            {
              tit: '中国轴承工业协会第八届五次理事会及第九届会员大会',
              img: '6.png',
            },
          ],
          tabsList: [
            {
              tit: "协会动态2023哈尔滨HRB轴承蓄势待发",
              dec: "传送带高速运转、切割机上下翻飞、磨床中火花与水花相映成辉勾勒出新春最美的图画。带着哈尔滨元素的中国轴承知名品牌...",
            },
            {
              tit: "无锡机械工业2023年经济运行分析... ",
              dec: "2023年，受突如其来的新冠肺炎疫情和中美贸易战影响，无锡机械工业2023年经济，呈现前低后高、逐月回升的走...",
            },
            {
              tit: "中央财政奖补资金支持“专精特新”...",
              dec: "日前，财政部、工业和信息化部联合印发《关于支持“专精特新”中小企业高质量发展的通知》，明确中央财政安排100亿元以上奖补资...",
            }
          ]
        },
        {
          id: "3",
          name: "通知公告",
          img: '13.png',
          swiper: [
            {
              tit: '中国轴承工业协会第八届五次理事会及第九届会员大会',
              img: '6.png',
            },
            {
              tit: '中国轴承工业协会第八届五次理事会及第九届会员大会',
              img: '5.png',
            },
            {
              tit: '中国轴承工业协会第八届五次理事会及第九届会员大会',
              img: '13.png',
            },
            {
              tit: '中国轴承工业协会第八届五次理事会及第九届会员大会',
              img: '51.png',
            },
            {
              tit: '中国轴承工业协会第八届五次理事会及第九届会员大会',
              img: '6.png',
            },
          ],
          tabsList: [
            {
              tit: "协会动态2023哈尔滨HRB轴承蓄势待发",
              dec: "传送带高速运转、切割机上下翻飞、磨床中火花与水花相映成辉勾勒出新春最美的图画。带着哈尔滨元素的中国轴承知名品牌...",
            },
            {
              tit: "无锡机械工业2023年经济运行分析... ",
              dec: "2023年，受突如其来的新冠肺炎疫情和中美贸易战影响，无锡机械工业2023年经济，呈现前低后高、逐月回升的走...",
            },
            {
              tit: "中央财政奖补资金支持“专精特新”...",
              dec: "日前，财政部、工业和信息化部联合印发《关于支持“专精特新”中小企业高质量发展的通知》，明确中央财政安排100亿元以上奖补资...",
            }
          ]
        },
        {
          id: "4",
          name: "交流论坛",
          img: '13.png',
          swiper: [
            {
              tit: '中国轴承工业协会第八届五次理事会及第九届会员大会',
              img: '6.png',
            },
            {
              tit: '中国轴承工业协会第八届五次理事会及第九届会员大会',
              img: '5.png',
            },
            {
              tit: '中国轴承工业协会第八届五次理事会及第九届会员大会',
              img: '13.png',
            },
            {
              tit: '中国轴承工业协会第八届五次理事会及第九届会员大会',
              img: '51.png',
            },
            {
              tit: '中国轴承工业协会第八届五次理事会及第九届会员大会',
              img: '6.png',
            },
          ],
          tabsList: [
            {
              tit: "协会动态2023哈尔滨HRB轴承蓄势待发",
              dec: "传送带高速运转、切割机上下翻飞、磨床中火花与水花相映成辉勾勒出新春最美的图画。带着哈尔滨元素的中国轴承知名品牌...",
            },
            {
              tit: "无锡机械工业2023年经济运行分析... ",
              dec: "2023年，受突如其来的新冠肺炎疫情和中美贸易战影响，无锡机械工业2023年经济，呈现前低后高、逐月回升的走...",
            },
            {
              tit: "中央财政奖补资金支持“专精特新”...",
              dec: "日前，财政部、工业和信息化部联合印发《关于支持“专精特新”中小企业高质量发展的通知》，明确中央财政安排100亿元以上奖补资...",
            }
          ]
        },
        {
          id: "5",
          name: "行业政策",
          img: '13.png',
          swiper: [
            {
              tit: '中国轴承工业协会第八届五次理事会及第九届会员大会',
              img: '6.png',
            },
            {
              tit: '中国轴承工业协会第八届五次理事会及第九届会员大会',
              img: '5.png',
            },
            {
              tit: '中国轴承工业协会第八届五次理事会及第九届会员大会',
              img: '13.png',
            },
            {
              tit: '中国轴承工业协会第八届五次理事会及第九届会员大会',
              img: '51.png',
            },
            {
              tit: '中国轴承工业协会第八届五次理事会及第九届会员大会',
              img: '6.png',
            },
          ],
          tabsList: [
            {
              tit: "协会动态2023哈尔滨HRB轴承蓄势待发",
              dec: "传送带高速运转、切割机上下翻飞、磨床中火花与水花相映成辉勾勒出新春最美的图画。带着哈尔滨元素的中国轴承知名品牌...",
            },
            {
              tit: "无锡机械工业2023年经济运行分析... ",
              dec: "2023年，受突如其来的新冠肺炎疫情和中美贸易战影响，无锡机械工业2023年经济，呈现前低后高、逐月回升的走...",
            },
            {
              tit: "中央财政奖补资金支持“专精特新”...",
              dec: "日前，财政部、工业和信息化部联合印发《关于支持“专精特新”中小企业高质量发展的通知》，明确中央财政安排100亿元以上奖补资...",
            }
          ]
        }
      ],
      tabIndex: 0,
      indexSwiper: 0,
      navList: [
        {
          tit: "资讯服务",
          engTit: "information service",
          dec: "基于数据挖掘以及内含推荐引擎的门户网站,它为用户推荐有价值的、个性化的信息,提供连接企业与整个行业的信息的新型服务",
          list: [
            {
              img: "icon4.png",
              name: "展会活动",
              dec: "提供展会服务活动"
            },
            {
              img: "icon1.png",
              name: "政策资讯",
              dec: "实时政策动态信息"
            },
            {
              img: "icon2.png",
              name: "新闻动态",
              dec: "更新最新新闻消息"
            },
            {
              img: "icon3.png",
              name: "交流论坛",
              dec: "提供交流场所论坛活动服务"
            }
          ],
        },
        {
          tit: "供销服务",
          engTit: "information service",
          dec: "供销服务基于数据挖掘以及内含推荐引擎的门户网站,它为用户推荐有价值的、个性化的信息,提供连接企业与整个行业的信息的新型服务",
          list: [
            {
              img: "icon4.png",
              name: "供销服务展会活动",
              dec: "供销服务提供展会服务活动"
            },
            {
              img: "icon1.png",
              name: "政策资讯",
              dec: "实时政策动态信息"
            },
            {
              img: "icon2.png",
              name: "新闻动态",
              dec: "更新最新新闻消息"
            },
            {
              img: "icon3.png",
              name: "交流论坛",
              dec: "提供交流场所论坛活动服务"
            }
          ],
        },
        {
          tit: "技术服务",
          engTit: "information service",
          dec: "基于数据挖掘以及内含推荐引擎的门户网站,它为用户推荐有价值的、个性化的信息,提供连接企业与整个行业的信息的新型服务",
          list: [
            {
              img: "icon4.png",
              name: "展会活动",
              dec: "提供展会服务活动"
            },
            {
              img: "icon1.png",
              name: "政策资讯",
              dec: "实时政策动态信息"
            },
            {
              img: "icon2.png",
              name: "新闻动态",
              dec: "更新最新新闻消息"
            },
            {
              img: "icon3.png",
              name: "交流论坛",
              dec: "提供交流场所论坛活动服务"
            }
          ],
        },
        {
          tit: "金融服务",
          engTit: "information service",
          dec: "基于数据挖掘以及内含推荐引擎的门户网站,它为用户推荐有价值的、个性化的信息,提供连接企业与整个行业的信息的新型服务",
          list: [
            {
              img: "icon4.png",
              name: "展会活动",
              dec: "提供展会服务活动"
            },
            {
              img: "icon1.png",
              name: "政策资讯",
              dec: "实时政策动态信息"
            },
            {
              img: "icon2.png",
              name: "新闻动态",
              dec: "更新最新新闻消息"
            },
            {
              img: "icon3.png",
              name: "交流论坛",
              dec: "提供交流场所论坛活动服务"
            }
          ],
        },
        {
          tit: "智能制造",
          engTit: "information service",
          dec: "基于数据挖掘以及内含推荐引擎的门户网站,它为用户推荐有价值的、个性化的信息,提供连接企业与整个行业的信息的新型服务",
          list: [
            {
              img: "icon4.png",
              name: "展会活动",
              dec: "提供展会服务活动"
            },
            {
              img: "icon1.png",
              name: "政策资讯",
              dec: "实时政策动态信息"
            },
            {
              img: "icon2.png",
              name: "新闻动态",
              dec: "更新最新新闻消息"
            },
            {
              img: "icon3.png",
              name: "交流论坛",
              dec: "提供交流场所论坛活动服务"
            }
          ],
        },

        {
          tit: "仓储物流",
          engTit: "information service",
          dec: "基于数据挖掘以及内含推荐引擎的门户网站,它为用户推荐有价值的、个性化的信息,提供连接企业与整个行业的信息的新型服务",
          list: [
            {
              img: "icon4.png",
              name: "展会活动",
              dec: "提供展会服务活动"
            },
            {
              img: "icon1.png",
              name: "政策资讯",
              dec: "实时政策动态信息"
            },
            {
              img: "icon2.png",
              name: "新闻动态",
              dec: "更新最新新闻消息"
            },
            {
              img: "icon3.png",
              name: "交流论坛",
              dec: "提供交流场所论坛活动服务"
            }
          ],
        },
        {
          tit: "数据服务 ",
          engTit: "information service",
          dec: "基于数据挖掘以及内含推荐引擎的门户网站,它为用户推荐有价值的、个性化的信息,提供连接企业与整个行业的信息的新型服务",
          list: [
            {
              img: "icon4.png",
              name: "展会活动",
              dec: "提供展会服务活动"
            },
            {
              img: "icon1.png",
              name: "政策资讯",
              dec: "实时政策动态信息"
            },
            {
              img: "icon2.png",
              name: "新闻动态",
              dec: "更新最新新闻消息"
            },
            {
              img: "icon3.png",
              name: "交流论坛",
              dec: "提供交流场所论坛活动服务"
            }
          ],
        }
      ],
      navIndex: 0,
      jiejueList: [
        {
          img: "a1.png",
          tit: "汽车领域",
          dec: "用柴油机智能服务云平台，赋能柴油发动机客户降低服务成本，提升售后服务效率。"
        },
        {
          img: "a2.png",
          tit: "工程机械",
          dec: "通过对工程机械设备的远程控制，实现设备的物联呈现以及设备数据的积累，有效提高售后服务能力。"
        },
        {
          img: "a3.png",
          tit: "精密机床",
          dec: "通过设备物联，提升设备价值；远程定位故障，降低服务成本；售后服务和解锁机功能实现设备远程监控。"
        },
        {
          img: "a4.png",
          tit: "冶金装备",
          dec: "通过提供改进操作流程和整合现有工厂设备的MOM/MES软件解决方案来帮助您改善环境保护绩效、提高能源效率、价格波动和激烈的竞争。"
        },
        {
          img: "a5.png",
          tit: "水泥装备",
          dec: "依托工业互联网平台，针对能源行业生产维护过程中的安全保障问题，打通全部人员管理信息环节，打造新型企业安全绩效管理体系"
        },
        {
          img: "a6.png",
          tit: "风电装备",
          dec: "云平台，实时工艺过程展示与大数据分析，实现从单一的设备提供商向设备软件硬件整套解决方案服务商的数字化转型。"
        },
      ],
      youqingList: ['c1.png', 'c2.png', 'c3.png', 'c4.png', 'c5.png', 'c6.png']
    }
  },

  mounted() {

  },

  created() {
    this.bigImg = this.tabs[0].swiper[0].img
    this.bigTit = this.tabs[0].swiper[0].tit
  },
  methods: {
    // tab切换
    onChange(index) {
      this.tabIndex = index
      this.bigImg = this.tabs[index].swiper[0].img
      this.bigTit = this.tabs[index].swiper[0].tit
      this.indexSwiper = 0
    },
    // 轮播图切换
    onClick(item, index) {
      this.indexSwiper = index
      this.bigImg = item.img
      this.bigTit = item.tit
    },
    onNav(index) {
      this.navIndex = index
    },
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.youqing {
  padding-top: 66px;
}

.youqing-ul ul {
  display: flex;
  flex-wrap: wrap;

}

.youqing-ul {
  margin-top: 58px;
}

.youqing-ul ul li {
  width: 186px;
  height: 66px;
  margin-right: 16px;
}

.youqing-ul ul li:nth-of-type(6n) {
  margin-right: 0;
}

.youqing-ul ul li img {
  width: 100%;
  height: 100%;
}

.gg img {
  width: 100%;
  height: 158px;
}

.jiejue-box h2 {
  font-size: 20px;
  font-weight: 400;
  color: #333333;
}

.jiejue-box p {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 24px;
}

.jiejue-ul {
  margin-top: 78px;
}

.jiejue-ul ul li img {
  display: block;
  width: 100%;
  height: 174px;
}

.jiejue-box {
  padding: 30px 35px 50px;
}

.jiejue-ul ul {
  display: flex;
  flex-wrap: wrap;
}

.jiejue-ul ul li {
  width: 360px;
  background: #FFFFFF;
  border: 1px solid #E8E8E8;
  border-radius: 4px;
  margin-right: 60px;
  margin-bottom: 34px;
}

.jiejue-ul ul li:nth-of-type(3n) {
  margin-right: 0;
}

.jiejue {
  padding: 70px 0;
}

.list-right h4 {
  font-size: 16px;
  font-weight: bold;
  color: #666666;
  margin-bottom: 12px;
}

.list-right h5 {
  font-size: 12px;
  font-weight: 400;
  color: #B1B1B1;
}

.list-right {
  flex: 1;
}

.list ul li img {
  width: 30px;
  height: 37px;
  margin-right: 20px;
  flex-shrink: 0;
}

.list ul {
  display: flex;
  flex-wrap: wrap;

}

.list ul li {
  width: 284px;
  height: 84px;
  background: #FFFFFF;
  border: 1px solid #F0F2F8;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 27px;
  padding: 0 20px;
  margin-bottom: 20px;
}

.list ul li:nth-of-type(3n) {
  margin-right: 0;
}

.dec {
  margin-bottom: 36px;
}

.dec p {
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 28px;
}

.tit-box {
  margin-bottom: 30px;
}

.tit-box h2 {
  font-size: 22px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 10px;
}

.tit-box h3 {
  font-size: 14px;
  font-weight: 400;
  color: #888888;
}

.chanye-right {
  padding: 50px;
  flex: 1;
}

.nav ul li {
  font-size: 16px;
  font-weight: 400;
  color: #515151;
  text-align: center;
  margin-bottom: 30px;
  cursor: pointer;
}

.nav ul li:last-of-type {
  margin-bottom: 0;
}

.nav ul li.on::after {
  content: "";
  display: block;
  width: 40px;
  height: 3px;
  background: #2965F7;
  margin: 0 auto;
  margin-top: 10px;
}

.nav {
  width: 192px;
  background: #EDEDED;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.chanye-box {
  background: #FFFFFF;
  border: 1px solid #F0F2F8;
  display: flex;
  justify-content: space-between;
  height: 486px;
}

.chanye-w {
  padding: 80px 0 100px;
}

.tit-dec {
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  color: #FFFFFF;
  margin-top: 15px;
  margin-bottom: 66px;
}

.tit-h2 {
  font-size: 32px;
  font-weight: 400;
  color: #333333;
  text-align: center;
}

.tit-h2::after {
  content: "";
  display: block;
  margin: 0 auto;
  margin-top: 14px;
  width: 58px;
  height: 4px;
  background: #2965F7;
}

.write {
  color: #fff;
}

.write.tit-h2::after {
  background: #fff;
}

.hexin {
  padding: 84px 0;
}

.hexin img {
  width: 100%;
  height: 988px;
}

.more {
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  color: #989898;
  margin-top: 82px;
  cursor: pointer;
}

.xiehui-right {
  width: 394px;
}

.swiper-item {
  margin-top: 20px;
}

.swiper-item ul {
  white-space: nowrap;
  overflow-x: scroll;

}

.swiper-item ul li {
  background: #FFFFFF;
  margin-right: 19px;
  width: 119px;
  height: 83px;
  display: inline-block;
}

.swiper-item ul li img {
  width: 100%;
  height: 100%;
  display: block;
  margin: 0 auto;
  cursor: pointer;

}

.swiper-item ul li:last-of-type {
  margin-right: 0;
}

.swiper-item ul li.on {
  border: 2px solid #4167C5;
}

.swiper {
  width: 100%;
  height: 290px;
  position: relative;
}

.swiper img {
  width: 100%;
  height: 100%;
}

.swiper h2 {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  line-height: 44px;
  background: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  text-align: center;
}

.lunbo {
  width: 100%;
}

.buton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 144px;
  height: 50px;
  background: #FFFFFF;
  cursor: pointer;
}

.buton img {
  width: 29px;
  height: 21px;
  margin-right: 7px;
}

.buton span {
  font-size: 20px;
  font-weight: 400;
  color: #004BBE;
}

.xiehui-item-right {
  flex: 1;
}

.xiehui-item-right h2 {
  font-size: 18px;
  font-weight: 400;
  color: #333333;
  margin-bottom: 24px;
}

.xiehui-item-right p {
  font-size: 14px;
  font-weight: 400;
  color: #7B7B7B;
}

.xiehui-item ul li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 44px;
  cursor: pointer;
}

.xiehui-item ul li img {
  width: 136px;
  height: 102px;
  margin-right: 24px;
}

.tabs {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}

.tabs ul {
  display: flex;
  justify-content: center;
}

.tabs ul li {
  width: 146px;
  line-height: 44px;
  height: 44px;
  background: #fff;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  margin-right: 20px;
  text-align: center;
  cursor: pointer;
}

.tabs ul li:last-of-type {
  margin-right: 0;
}

.tabs ul li.on {
  background: #4167C5;
  font-weight: bold;
  color: #FFFFFF;
}

.xiehui-left {
  flex: 1;
  margin-right: 34px;
}

.xiehui-w {
  padding: 70px 0;
}

.xiehui-box {
  display: flex;
  justify-content: space-between;
}

.xiehui {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('~@/assets/39.png');
}

.banner img {
  width: 100%;
  height: 100%;
}

.chanye {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('~@/assets/20.png');
}
</style>
