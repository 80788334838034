<template>
	<!-- <div id="app" 
	v-if="this.$route.fullPath == '/Login'  ||this.$route.fullPath == '/login'  ||this.$route.fullPath == '/regist'
	||this.$route.fullPath == '/Regist'||this.$route.fullPath == '/protocol'||this.$route.fullPath == '/Forget' ||this.$route.fullPath == '/forget'">
		<router-view></router-view>
	</div> -->
	<div id="app">
		<my-header></my-header>
		<router-view></router-view>
		<my-footer></my-footer>
	</div>
</template>

<script>
import MyHeader from '@/components/header/header'
import MyFooter from '@/components/footer/footer'
export default {
	name: 'App',
	components: {
		MyHeader, MyFooter
	},
	// watch: {
	//   $route: {
	//     handler: function(route) {
	//     },
	//   }
	// },
}
</script>

<style>
.el-menu--collapse .el-menu .el-submenu,
.el-menu--popup {
	min-width: 150px !important;
}

.el-menu--horizontal .el-menu .el-menu-item,
.el-menu--horizontal .el-menu .el-submenu__title {
	height: 56px !important;
	line-height: 56px !important;
}

* {
	margin: 0;
	padding: 0;
	font-family: "微软雅黑";
	list-style: none;
	transition-property: color, border-color;
	transition-duration: 0.3s;
	box-sizing: border-box;
	moz-user-select: -moz-none;
	-moz-user-select: none;
	-o-user-select: none;
	-khtml-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	-webkit-user-drag: none;

}

input[type="button"],
input[type="submit"],
input[type="reset"] {
	-webkit-appearance: none;
	border-radius: 0;
}

input[type="submit"],
input[type="button"] {
	box-sizing: content-box;
}

a {
	color: inherit;
	text-decoration: none;
	font-family: "微软雅黑";
}

a:link,
a:active,
a:visited,
a:hover {
	background: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	-webkit-tap-highlight-color: transparent;
}

h2,
h3,
h4,
h5,
h6 {
	font-weight: normal;
}

body {
	/* min-width: 1200px; */
	background: #fff;
	color: #333;
	font-size: 16px;
	margin: 0 auto;
	overflow-x: hidden;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

th {
	font-weight: 400;
}

img {
	border: 0;
}

.con_img img {
	max-width: 100%;
	height: auto !important;
	display: block;
	margin: 0 auto;
}

textarea,
select,
option,
input {
	outline: none;
}

.left {
	float: left;
}

.right {
	float: right;
}

.fw400 {
	font-weight: 400;
}

.fw700 {
	font-weight: 700;
}

.clearBoth:after,
.clearboth:after {
	content: "";
	display: block;
	clear: both;
}

.bordernone {
	border: none !important;
}

.red {
	color: red;
}

.white {
	color: #fff;
}

.centerBox {
	margin: 0 auto;
	width: 100%;
	max-width: 1200px;
}

/* .centerBox:after {
	content: "";
	display: block;
	clear: both;
} */

/*每个页面公共css */
.flex-row {
	display: flex;
}

.flex-center {
	display: flex;
	align-items: center;
	justify-content: center;
}

.flex-col-center {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.flex-col {
	display: flex;
	flex-direction: column;
}

.flex-row-center {
	display: flex;
	align-items: center;
}

.flex-row-between {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

/* 状态栏高度 */
.status-bar {
	height: var(--status-bar-height);
	width: 100%;
}

/* 一行超出省略号 */
.ellipsis {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

/* 两行超出省略号 */
.ellipsis-2 {
	text-overflow: -o-ellipsis-lastline;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	line-clamp: 2;
	-webkit-box-orient: vertical;
}

/* 吸顶 */
.sticky {
	/* #ifndef APP-PLUS */
	position: -webkit-sticky;
	/* #endif */
	position: sticky;
	top: var(--window-top);
	z-index: 99;
}

/* // 定位居中 */
.absolute-center {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	border: 0;
	margin: auto;
}</style>
